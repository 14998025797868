<template>
  <div class="page">
    <div class="pageTitle">
      <img src="../assets/img/goBack.png" class="goBack" @click="goBack" />
      <span>公告</span>
    </div>
    <div class="noticeBox scrollView" v-html="detail"></div>
  </div>
</template>

<script>
export default {
  name: "noticePage",
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.detail = this.$store.getters.getNoticeDetail;
  },
  mounted() {},
  watch: {},
  methods: {
    goBack: function () {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.scrollView {
  height: calc(100% - 112px);
}

.noticeBox img {
  width: 678px;
}

.noticeBox {
  padding: 35px 36px;
  font-size: 33px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  letter-spacing: 2px;
  word-break: break-all;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.page {
  background: #fff;
}

.goBack {
  position: absolute;
  top: 5px;
  left: 36px;
  width: 18px;
  height: 36px;
}

.pageTitle {
  width: 100%;
  height: 51px;
  margin: 43px 0 18px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #404040;
  line-height: 51px;
  text-align: center;
}
</style>
