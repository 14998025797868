import request from "@/utils/request";

export function getPlace(params) {
  return request({
    url: "/api/v1/place",
    method: "get",
    params,
  });
}

export function getDict(params) {
  return request({
    url: "/api/v1/dict",
    method: "get",
    params,
  });
}

export function getAppointment(params) {
  return request({
    url: "/api/v1/appointment",
    method: "get",
    params,
  });
}

export function addAppointment(data) {
  return request({
    url: "/api/v1/appointment",
    method: "post",
    data,
  });
}
