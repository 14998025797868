<template>
  <div class="page">
    <div class="pageTitle">
      <img src="../assets/img/goBack.png" class="goBack" @click="goBack" />
      <span>企业活动预约</span>
    </div>
    <div class="repairBox scrollView">
      <div class="fillInBox">
        <div class="inputTittle">1.会议名称</div>
        <input
          type="text"
          class="inputStyle"
          v-model="formData.meeting_name"
          placeholder=""
        />
      </div>
      <div class="fillInBox">
        <div class="inputTittle">2.参会人数</div>
        <input
          type="number"
          class="inputStyle"
          v-model="formData.meeting_people"
          placeholder=""
        />
      </div>
      <div class="fillInBox">
        <div class="inputTittle">3.对接人姓名</div>
        <input
          type="text"
          class="inputStyle"
          v-model="formData.user_name"
          placeholder=""
        />
      </div>
      <div class="fillInBox">
        <div class="inputTittle">4.对接人电话</div>
        <input
          type="tel"
          maxlength="11"
          class="inputStyle"
          v-model="formData.user_phone"
          placeholder=""
        />
      </div>
      <div class="fillInBox">
        <div class="inputTittle">5.会议摆台样式</div>
        <div class="meetStyleBox">
          <template v-for="(item, index) in dictList">
            <div
              class="meetItem"
              :key="item.id"
              :class="dictIndex === index ? 'blueStyle' : ''"
              @click="tableTypeSelect(index)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
      <div class="tips" v-if="this.formData.price">
        预约{{ this.formData.place_name }}，收取场地费{{
          this.formData.price
        }}元/半天
      </div>
      <div class="submitBtn" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import { getDict, addAppointment } from "@/api/appointment";
import { Toast } from "vant";
export default {
  name: "businessPage",
  data() {
    return {
      formData: {
        place_id: undefined,
        place_name: undefined,
        appointment_date: undefined,
        appointment_type: undefined,
        appointment_type_name: undefined,
        activity_type: undefined,
        price: undefined,
        meeting_name: undefined,
        meeting_people: undefined,
        user_name: undefined,
        user_phone: undefined,
        meeting_table_type: undefined,
      },
      ruleMessage: {
        place_id: { required: "请选择预约地点" },
        appointment_date: { required: "请选择日期" },
        appointment_type: { required: "请选择时间" },
        activity_type: { required: "请选择活动类型" },
        meeting_name: { required: "请输入会议名称" },
        meeting_people: { required: "请输入参会人数" },
        user_name: { required: "请输入对接人姓名" },
        user_phone: { required: "请输入对接人电话" },
        meeting_table_type: { required: "请选择摆台样式" },
      },
      dictList: [],
      dictIndex: -1,
      submitLoading: false,
    };
  },
  beforeCreate() {
    let backups = this.$store.getters.getAppointmentBackups;
    if (!backups) {
      this.$router.replace({
        path: "/venueBooking",
      });
    }
  },
  created() {
    this.getDict();
  },
  mounted() {},
  beforeDestroy() {
    this.$store.dispatch("setAppointmentBackups", this.formData);
  },
  watch: {},
  methods: {
    goBack: function () {
      this.$router.back();
    },
    goAppointmentSubmit: function () {
      this.$router.replace({
        path: "/appointmentSubmit",
      });
    },
    getDict: function () {
      getDict({ code: "TABLE_TYPE" }).then(
        (response) => {
          if (response.code === 200) {
            this.dictList = response.data;
            this.getBackups();
          }
        },
        () => {}
      );
    },
    objectMergLeft: function (target, source) {
      Object.keys(target).forEach((property) => {
        if (Object.hasOwnProperty.call(source, property)) {
          target[property] = source[property];
        }
      });
      return target;
    },
    getBackups: function () {
      let backups = this.$store.getters.getAppointmentBackups;
      this.formData = this.objectMergLeft(
        Object.assign({}, this.formData),
        backups
      );
      if (this.formData.meeting_table_type) {
        for (let i = 0; i < this.dictList.length; i++) {
          if (this.formData.meeting_table_type === this.dictList[i].code) {
            this.dictIndex = i;
          }
        }
      }
    },
    tableTypeSelect: function (index) {
      this.dictIndex = index;
      this.formData.meeting_table_type = this.dictList[index].code;
    },
    submit: function () {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      if (!this.checkFormData()) {
        this.submitLoading = false;
        return;
      }
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      addAppointment(this.formData).then(
        (response) => {
          this.submitLoading = false;
          if (response.code === 200) {
            this.goAppointmentSubmit();
          }
        },
        () => {
          this.submitLoading = false;
        }
      );
    },
    checkFormData: function () {
      for (let key in this.formData) {
        if (
          Object.hasOwnProperty.call(this.ruleMessage, key) &&
          !this.formData[key]
        ) {
          Toast({
            message: this.ruleMessage[key]["required"],
            forbidClick: true,
            duration: 1500,
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.scrollView {
  height: calc(100% - 112px);
}

.tips {
  width: 678px;
  padding: 27px 36px;
  background: #f2f2f2;
  border-radius: 9px;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #878787;
  line-height: 38px;
  text-align: center;
  margin: 0 auto;
}

.meetStyleBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.meetItem {
  height: 82px;
  border-radius: 9px;
  border: 1px solid rgba(56, 56, 56, 0.1);
  padding: 0 27px;
  margin-right: 36px;
  margin-bottom: 36px;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #494949;
  line-height: 78px;
  white-space: nowrap;
}

.blueStyle {
  color: #4679d8;
  background: rgba(61, 110, 211, 0.15);
  border: 1px solid #3d6ed3;
}

.page {
  background: #fff;
}

.submitBtn {
  width: 678px;
  height: 100px;
  background: linear-gradient(127deg, #3d6ed3 0%, #72aaf1 100%);
  box-shadow: 0px 4px 18px 0px rgba(155, 194, 253, 1);
  border-radius: 9px;
  margin: 36px auto 49px;
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 97px;
  text-align: center;
}

.inputStyle,
.textareaStyle {
  color: #404040;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  -webkit-appearance: none;
  width: 678px;
  border-radius: 9px;
  border: 1px solid rgba(56, 56, 56, 0.1);
  padding: 20px 27px 22px;
}

.inputStyle {
  height: 82px;
}

.textareaStyle {
  line-height: 38px;
  height: 163px;
}

.inputTittle {
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #383838;
  line-height: 45px;
  margin-bottom: 27px;
}

.fillInBox {
  margin-bottom: 54px;
  width: 678px;
}

.repairBox {
  padding: 54px 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goBack {
  position: absolute;
  top: 3px;
  left: 36px;
  width: 18px;
  height: 36px;
}

.pageTitle {
  width: 100%;
  height: 51px;
  margin: 43px 0 18px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #404040;
  line-height: 51px;
  text-align: center;
}
</style>
