<template>
  <div class="page scrollView">
    <div class="pageTitle">
      <img src="../assets/img/goBack.png" class="goBack" @click="goBack" />
      <span>报修服务</span>
    </div>
    <div class="repairBox">
      <div class="fillInBox">
        <div class="inputTittle">1.报修地点</div>
        <input
          type="text"
          class="inputStyle"
          v-model="formData.place"
          placeholder="请填写门牌号"
        />
      </div>
      <div class="fillInBox">
        <div class="inputTittle">2.报修问题</div>
        <textarea
          class="textareaStyle"
          v-model="formData.problem"
          placeholder="简单描述您的维修需求"
        ></textarea>
      </div>
      <div class="fillInBox">
        <div class="inputTittle">3.联系电话</div>
        <input
          type="tel"
          maxlength="11"
          class="inputStyle"
          v-model="formData.phone"
          placeholder="留下办公室电话或手机号"
        />
      </div>
    </div>
    <div class="submitBtn" @click="submit">提交</div>
  </div>
</template>

<script>
import { addGuarantee } from "@/api/guarantee";
import { Toast } from "vant";
export default {
  name: "repairPage",
  data() {
    return {
      formData: {
        place: undefined,
        problem: undefined,
        phone: undefined,
      },
      ruleMessage: {
        place: { required: "报修地点不能为空" },
        problem: { required: "报修问题不能为空" },
        phone: { required: "联系电话不能为空" },
      },
      submitLoading: false,
    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    goBack: function () {
      this.$router.back();
    },
    submit: function () {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      if (!this.checkFormData()) {
        this.submitLoading = false;
        return;
      }
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      addGuarantee(this.formData).then(
        (response) => {
          this.submitLoading = false;
          if (response.code === 200) {
            this.goSubmittedPage();
          }
        },
        () => {
          this.submitLoading = false;
        }
      );
    },
    goSubmittedPage: function () {
      this.$router.replace({
        path: "/submittedPage",
      });
    },
    checkFormData: function () {
      for (let key in this.formData) {
        if (!this.formData[key]) {
          Toast({
            message: this.ruleMessage[key]["required"],
            forbidClick: true,
            duration: 1500,
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.page {
  background: #fff;
}

.submitBtn {
  width: 678px;
  height: 100px;
  background: linear-gradient(127deg, #3d6ed3 0%, #72aaf1 100%);
  box-shadow: 0px 4px 18px 0px rgba(155, 194, 253, 1);
  border-radius: 9px;
  margin: 37px auto;
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 97px;
  text-align: center;
}

.inputStyle,
.textareaStyle {
  color: #404040;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  -webkit-appearance: none;
  width: 678px;
  border-radius: 9px;
  border: 1px solid rgba(56, 56, 56, 0.1);
  padding: 20px 27px 22px;
}

.inputStyle {
  height: 82px;
}

.textareaStyle {
  line-height: 38px;
  height: 163px;
}

.inputTittle {
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #404040;
  line-height: 45px;
  margin-bottom: 27px;
}

.fillInBox {
  margin-bottom: 54px;
}

.repairBox {
  padding: 54px 45px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goBack {
  position: absolute;
  top: 5px;
  left: 36px;
  width: 18px;
  height: 36px;
}

.pageTitle {
  width: 100%;
  height: 51px;
  margin: 43px 0 18px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #404040;
  line-height: 51px;
  text-align: center;
}
</style>
