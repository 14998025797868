<template>
  <div class="page">
    <div class="submitBox">
      <img src="../assets/img/submitIcon.png" class="submitIcon" />
      <span>预约申请已提交</span>
      <span class="tips"
        >我们将尽快审核您的申请<br />
        请耐心等待短信通知</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "appointmentSubmit",
  created() {
    setTimeout(() => {
      this.$store.dispatch("setAppointmentBackups", null);
    }, 500);
  },
};
</script>

<style scoped>
.submitBox {
  width: 100%;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #383838;
  line-height: 51px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 30%;
  left: 0;
}

.submitIcon {
  width: 127px;
  height: 127px;
  margin-bottom: 54px;
}

.tips {
  margin-top: 27px;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(56, 56, 56, 0.3);
  line-height: 38px;
  text-align: center;
}
</style>
