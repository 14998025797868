<template>
  <div class="page scrollView">
    <div class="venueBox" v-if="detail.appointment_banner">
      <img :src="toMedia(detail.appointment_banner)" class="imgBg" />
      <!--      <div class="noticeWord">
        <span>千岛湖智谷大厦</span>
        <span>场地预约</span>
      </div> -->
    </div>
    <div class="orderBox">
      <div class="itemBox">
        <div class="itemTitle">预约地点</div>
        <div class="placeBox">
          <div
            class="placeLi"
            :class="index == placeIndex ? 'activeBlue' : ''"
            v-for="(item, index) in placeList"
            :key="item.id"
            @click="selectPlace(index)"
          >
            <span>{{ item.name }}</span>
            <span>最多容纳{{ item.max_people }}人</span>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="itemTitle">选择日期</div>
        <div class="dateChoose" @click="showPopup()">
          <span>{{ formatDate(dateRange.value) }}</span>
          <img src="../assets/img/trg.png" class="trg" />
        </div>
      </div>
      <div class="itemBox">
        <div class="itemTitle">选择时间</div>
        <div class="timeChoose">
          <template v-for="(item, index) in timeList">
            <div
              class="timeBox"
              :key="index"
              :class="
                item.canClick
                  ? timeIndex === index
                    ? 'blueStyle'
                    : 'whiteStyle'
                  : 'grayStyle'
              "
              @click="selectTime(index)"
            >
              {{ item.name }} {{ item.canClick ? "可预约" : "已预约" }}
            </div>
          </template>
        </div>
      </div>
      <div class="itemBox">
        <div class="itemTitle">活动类型</div>
        <div class="timeChoose">
          <div
            class="timeBox"
            :class="formData.activity_type === 2 ? 'blueStyle' : 'whiteStyle'"
            @click="formData.activity_type = 2"
          >
            党群活动
          </div>
          <div
            class="timeBox"
            :class="formData.activity_type === 1 ? 'blueStyle' : 'whiteStyle'"
            @click="formData.activity_type = 1"
          >
            企业活动
          </div>
        </div>
      </div>
    </div>
    <div class="nextStep" @click="nextStep">下一步</div>
    <van-popup v-model="showDatePopup" position="bottom">
      <van-datetime-picker
        ref="datePicker"
        type="date"
        title="选择年月日"
        :min-date="dateRange.min"
        :max-date="dateRange.max"
        v-model="dateRange.currentValue"
        @cancel="showDatePopup = false"
        @confirm="dateConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { getPlace, getAppointment } from "@/api/appointment";
import { getInfo } from "@/api/base";
import moment from "moment";
import { Toast } from "vant";
import { toMedia } from "@/utils/request";
export default {
  name: "venueBooking",
  data() {
    return {
      showDatePopup: false,
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
      chooseDate: this.formatDate(new Date()),
      activityType: 0,
      //
      placeList: [],
      placeIndex: -1,
      dateRange: {
        interval: 15,
        min: undefined,
        max: undefined,
        value: undefined,
        currentValue: undefined,
      },
      formData: {
        place_id: undefined,
        place_name: undefined,
        appointment_date: undefined,
        appointment_type: undefined,
        appointment_type_name: undefined,
        activity_type: undefined,
        price: undefined,
      },
      ruleMessage: {
        place_id: { required: "请选择预约地点" },
        appointment_date: { required: "请选择日期" },
        appointment_type: { required: "请选择时间" },
        activity_type: { required: "请选择活动类型" },
      },
      timeList: [
        { name: "9:00-12:00", id: 1, canClick: true },
        { name: "14:00-17:00", id: 2, canClick: true },
      ],
      timeIndex: -1,
      detail: {},
    };
  },
  created() {
    this.getInfo();
    this.initDate();
    this.getPlace();
  },
  mounted() {},
  methods: {
    toMedia,
    getInfo: function () {
      getInfo().then(
        (response) => {
          if (response.code === 200) {
            this.detail = response.data;
          }
        },
        () => {}
      );
    },
    initDate: function () {
      let today = new Date();
      let max = new Date();
      max.setDate(today.getDate() + this.dateRange.interval);
      this.dateRange.min = today;
      this.dateRange.value = today;
      this.formData.appointment_date = moment(today).format("YYYY-MM-DD");
      this.dateRange.max = max;
    },
    getPlace: function () {
      getPlace().then(
        (response) => {
          if (response.code === 200) {
            this.placeList = response.data;
            this.checkBackups();
          }
        },
        () => {}
      );
    },
    checkBackups: function () {
      let backups = this.$store.getters.getAppointmentBackups;
      if (backups) {
        this.formData = this.objectMergLeft(
          Object.assign({}, this.formData),
          backups
        );
        if (this.formData.place_id) {
          for (let i = 0; i < this.placeList.length; i++) {
            if (this.formData.place_id == this.placeList[i].id) {
              this.placeIndex = i;
            }
          }
        }
        if (this.formData.appointment_date) {
          this.dateRange.value = new Date(this.formData.appointment_date);
        }
        if (this.formData.appointment_type) {
          for (let i = 0; i < this.timeList.length; i++) {
            if (this.formData.appointment_type === this.timeList[i].id) {
              this.timeIndex = i;
            }
          }
        }
        this.getCanAppointmentTime();
      }
    },
    objectMergLeft: function (target, source) {
      Object.keys(target).forEach((property) => {
        if (Object.hasOwnProperty.call(source, property)) {
          target[property] = source[property];
        }
      });
      return target;
    },
    getCanAppointmentTime: function () {
      if (this.formData.place_id && this.formData.appointment_date) {
        let data = {};
        data.place_id = this.formData.place_id;
        data.appointment_date = this.formData.appointment_date;
        getAppointment(data).then(
          (response) => {
            if (response.code === 200) {
              this.timeList[0].canClick = response.data.type1;
              this.timeList[1].canClick = response.data.type2;
              if (
                this.timeIndex > -1 &&
                !this.timeList[this.timeIndex].canClick
              ) {
                this.timeIndex = -1;
                this.formData.appointment_type = undefined;
                this.formData.appointment_type_name = undefined;
              }
            } else {
              this.timeList.forEach((v) => {
                v.canClick = false;
              });
              this.timeIndex = -1;
              this.formData.appointment_type = undefined;
              this.formData.appointment_type_name = undefined;
            }
          },
          () => {}
        );
      } else {
        this.timeList.forEach((v) => {
          v.canClick = true;
        });
      }
    },
    selectPlace: function (index) {
      this.placeIndex = index;
      this.formData.place_id = this.placeList[index].id;
      this.formData.place_name = this.placeList[index].name;
      this.formData.price = this.placeList[index].price;
      this.getCanAppointmentTime();
    },
    selectTime: function (index) {
      if (this.timeList[index].canClick) {
        this.timeIndex = index;
        this.formData.appointment_type = this.timeList[index].id;
        this.formData.appointment_type_name = this.timeList[index].name;
      }
    },
    showPopup: function () {
      this.dateRange.currentValue = this.dateRange.value;
      this.showDatePopup = true;
    },
    dateConfirm: function (value) {
      this.dateRange.value = value;
      this.formData.appointment_date = moment(value).format("YYYY-MM-DD");
      this.showDatePopup = false;
      this.getCanAppointmentTime();
    },
    nextStep: function () {
      if (!this.checkFormData()) {
        return;
      }
      let backups = this.$store.getters.getAppointmentBackups;
      if (backups) {
        this.$store
          .dispatch(
            "setAppointmentBackups",
            this.objectMergLeft(backups, this.formData)
          )
          .then(() => {
            this.goBusinessPage();
          });
      } else {
        this.$store
          .dispatch("setAppointmentBackups", this.formData)
          .then(() => {
            this.goBusinessPage();
          });
      }
    },
    checkFormData: function () {
      for (let key in this.formData) {
        if (
          Object.hasOwnProperty.call(this.ruleMessage, key) &&
          !this.formData[key]
        ) {
          Toast({
            message: this.ruleMessage[key]["required"],
            forbidClick: true,
            duration: 1500,
          });
          return false;
        }
      }
      return true;
    },

    goBusinessPage: function () {
      if (this.formData.activity_type === 2) {
        this.$router.push({
          path: "/partyMasses",
        });
      } else if (this.formData.activity_type === 1) {
        this.$router.push({
          path: "/businessPage",
        });
      }
    },
    /**
     * 提交时间
     */
    formatDate: function (date) {
      return moment(date).format("YYYY年MM月DD日");
    },
  },
};
</script>

<style scoped>
.nextStep {
  width: 678px;
  height: 100px;
  background: linear-gradient(127deg, #3d6ed3 0%, #72aaf1 100%);
  box-shadow: 0px 4px 18px 0px rgba(155, 194, 253, 1);
  border-radius: 9px;
  margin: 91px auto 73px;
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 100px;
  text-align: center;
}

.timeBox {
  height: 82px;
  padding: 0 27px;
  line-height: 78px;
  text-align: center;
  border-radius: 9px;
  margin-right: 36px;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: nowrap;
}

.grayStyle {
  background: #f5f5f5;
  color: #c2c2c2;
  border: 2px solid rgba(56, 56, 56, 0.1);
}

.blueStyle {
  color: #4679d8;
  background: rgba(61, 110, 211, 0.15);
  border: 2px solid #3d6ed3;
}

.whiteStyle {
  color: #404040;
  background: #ffffff;
  border: 2px solid rgba(56, 56, 56, 0.1);
}

.timeChoose {
  display: flex;
}

.trg {
  width: 16px;
  margin-left: 27px;
  margin-top: -2px;
}

.dateChoose {
  width: 300px;
  height: 82px;
  padding: 0 27px;
  border-radius: 9px;
  border: 2px solid rgba(56, 56, 56, 0.1);
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: lighter;
  color: #383838;
  line-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.itemBox {
  margin-bottom: 54px;
  width: 678px;
}

.itemBox:last-child {
  margin: 0;
}

.placeLi {
  width: 678px;
  border-radius: 9px;
  padding: 22px 27px;
  border: 2px solid rgba(56, 56, 56, 0.1);
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;
}

.placeLi span:first-child {
  margin-bottom: 5px;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: lighter;
  color: #383838;
  line-height: 38px;
}

.placeLi span:last-child {
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: lighter;
  /* color: rgba(56, 56, 56, 0.3); */
  color: #b2b2b2;
  line-height: 38px;
}

.placeLi:last-child {
  margin: 0;
}

.activeBlue {
  /* padding: 20px 25px; */
  background: rgba(61, 110, 211, 0.15);
  border: 2px solid #3d6ed3;
}

.activeBlue span:first-child {
  color: #3d6ed3;
}

.activeBlue span:last-child {
  /* color: rgba(61, 110, 211, 0.3); */
  color: #8ba9e5;
}

.itemTitle {
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #383838;
  line-height: 45px;
  margin-bottom: 27px;
}

.orderBox {
  width: 100%;
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noticeWord {
  padding: 40px 36px;
  width: 200px;
  display: flex;
  flex-direction: column;
}

.noticeWord span:first-child {
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #434f66;
  line-height: 38px;
  white-space: nowrap;
}

.noticeWord span:last-child {
  font-size: 45px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #434f66;
  line-height: 65px;
  letter-spacing: 1px;
  white-space: nowrap;
}

.imgBg {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.venueBox {
  width: 678px;
  height: 185px;
  border-radius: 9px;
  margin: 43px auto 82px;
  overflow: hidden;
}
</style>
